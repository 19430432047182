<template>
  <div>
    <b-modal
      id="help-modal"
      ref="help-modal"
      hide-footer
      centered
      size="lg"
    >
      <div class="d-block py-2 px-5" v-if="page == 0">
        <div class="text-center">
          <h3 class="pb-1">Welcome to Mental Health Check</h3>
          <p class="font-weight-normal">
            Get started by selecting the option you feel has the greatest impact
            on your mental health.
          </p>
        </div>
        <div class="text-center mt-3">
          <b-button variant="primary" @click="closeModal()"> Got it </b-button>

          <div class="mt-1" v-if="isClickedOnButton == false">
            <span class="cursor-pointer" @click="dontShowAgain(0)">
              Don’t show me this message again
            </span>
          </div>
        </div>
      </div>
      <div class="d-block py-2 px-5" v-else-if="page == 1">
        <div class="text-center">
          <p class="font-weight-normal">
            We all experience life situations that impact our mental health in
            the short, medium and long term.
          </p>
          <p class="font-weight-normal">
            For this mental health check, select a life situation (past, present
            or future) that you feel has an impact on your overall mental health
            and wellbeing.
          </p>
          <p class="font-weight-normal">
            Remember, the more mental health checks you have for the life
            situations that impact your mental health, the more accurate your
            mental health trait score will be for you.
          </p>
          <p class="font-weight-normal">
            Once you complete this mental health check, select option to ‘Start
            another mental health check’ and complete as many mental health
            checks as you feel reflects your life situation.
          </p>
        </div>
        <div class="text-center mt-3">
          <b-button variant="primary" @click="closeModal()"> Got it </b-button>

          <div class="mt-1" v-if="isClickedOnButton == false">
            <span class="cursor-pointer" @click="dontShowAgain(1)">
              Don’t show me this message again
            </span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EventBus from "../../main.js";
import { BOverlay, BModal, BButton, BLink, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BModal,
    BButton,
    BLink,
    BRow,
    BCol,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isClickedOnButton: false,
    };
  },
  methods: {
    openModal() {
      if (this.$refs["help-modal"] != undefined) {
        // Show modal
        this.$refs["help-modal"].show();
      }
    },
    closeModal() {
      this.$refs["help-modal"].hide();
    },
    dontShowAgain(page) {
      if (page == 0) {
        localStorage.setItem("dont-show-page-0", true);
      } else {
        localStorage.setItem("dont-show-page-1", true);
      }
      this.closeModal();
    },
  },
  mounted() {
    // Event for opening Finished trait support modal
    EventBus.$on("openHelpModal", (btnClicked) => {
      this.isClickedOnButton = btnClicked;
      this.openModal();
    });
  },
};
</script>

<style scoped>
@import "../../assets/css/modals-style.css";
</style>