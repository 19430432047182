<template>
  <b-overlay :show="show" rounded="sm" class="w-100">
    <div class="questionnaire-container py-3">
      <div v-if="page == 0">
        <div class="helpBtn" @click="openHelpModal(true)">Help</div>
        <help :page="0"/>

        <div class="questionBox py-3">
          <div class="text-center mb-2">
            <h3>What do you need support with?</h3>
            <p>Please select from the options below</p>
            <hr class="mhc-divider m-auto" />
          </div>

          <div class="questionContainer">
            <div class="optionContainer">
              <div v-if="questionnaireCategories.length > 1">
                <div
                  v-for="(qCategory, index) in questionnaireCategories"
                  :key="index"
                  class="d-flex"
                >
                  <div :class="'w-100 option ' + addIsDisabledOption(qCategory)" @click="chooseCategory(qCategory)">
                    <span class="option-click">{{ qCategory.name }}</span>
                  </div>

                  <div class="d-flex align-items-center pb-1" style="padding-left: 10px;" v-b-tooltip.hover.right="qCategory.description">
                    <feather-icon icon="InfoIcon" class="color-blue" size="20" />
                  </div>
                </div>
              </div>
              <div v-else class="text-center">
                <p>We currently have no questionnaires</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div v-if="page == 1">
        <help :page="1"/>
        
        <b-row
          class="justify-content-center no-gutters m-auto mb-2"
          style="max-width: 90%"
        >
          <b-col md="2" class="text-left col-6">
            <b-link
              class="header-pagination"
              v-on:click="goBack"
              v-if="page == 1"
            >
              <feather-icon icon="ArrowLeftIcon" size="18" />
              Back</b-link
            >
          </b-col>
          <b-col md="8" class="col-12 mhc-header-title">
            <div style="max-width: 65ch">
              <b-row class="no-gutters text-center">
                <h3 class="m-auto color-mhc-dark">{{ questionnaireCategory }}</h3>
              </b-row>
              <b-row class="no-gutters">
                <p class="m-auto text-center">
                  {{ questionnaireCategory }}. You are free to stop
                  whenever you choose.
                </p>
              </b-row>
            </div>
          </b-col>
          <b-col md="2" class="text-left col-6 mhc-header-stop">
            <div class="helpBtn" @click="openHelpModal(true)">Help</div>
          </b-col>
        </b-row>
        <b-row
          class="justify-content-between no-gutters m-auto mb-2"
          style="max-width: 90%"
        >
          <b-progress
            max="100"
            variant="mhc-dark"
            height=".5rem"
            width="100%"
            class="mt-2 mb-3"
            style="width: 100%"
          />
        </b-row>
        
        <div class="questionBox pb-3">
          <h3 class="text-center" v-if="showSubcategories() && questionnaireCategory == 'My mental health and how I feel'">
            How are you really doing?
          </h3>
          <h3 class="text-center" v-else>
            Select an option that best matches your situation
          </h3>
          <p class="text-center">Select one option</p>
          <hr class="mhc-divider m-auto" />

          <div class="questionContainer max-w-450 mt-2">
            
            <b-overlay :show="filterOverlay" rounded="sm">
              <div class="letterFilter px-1" v-if="choosenQuestionnaireCategory.name == 'My mental health and my life situation(s)'">
                <span class="text-uppercase" v-for="(letter, index) in lettersSubcategories" :key="index" @click="getSubcategories(letter)" v-bind:class="{'green-text': letter.flag, 'text-underline': firstLetterSubactegory == letter.letter}">
                  {{ letter.letter }}
                </span>
              </div>

              <div class="px-1">
                <b-form-input v-model="searchSubcategory" placeholder="Search term" class="mb-1"></b-form-input>
              </div>

              <vue-perfect-scrollbar
                ref="scroll-bar"
                :settings="perfectScrollbarSettings"
                class="scrollable-container media-list scroll-area"
                style="height: 300px"
                suppressScrollX="false"
              >
                <div class="optionContainer px-1">
                  <div v-if="questionnaireSubCategories.length > 0">
                    <div
                      class="option"
                      v-for="(qSubCategory, index) in filteredList"
                      :key="index"
                      @click="chooseSubCategory(qSubCategory.id, qSubCategory.name)"
                    >
                      <span class="option-click">{{ qSubCategory.name }}</span>
                    </div>
                  </div>
                </div>
              </vue-perfect-scrollbar>
            </b-overlay>
          </div>
        </div>
      </div>

      <div v-if="page == 2">
        <b-row
          class="justify-content-center no-gutters m-auto mb-2"
          style="max-width: 90%"
        >
          <b-col md="2" class="text-left col-6">
            <b-link
              class="header-pagination"
              v-on:click="goBack"
            >
              <feather-icon icon="ArrowLeftIcon" size="18" />
              Back</b-link
            >
          </b-col>
          <b-col md="8" class="col-12">
            <div style="max-width:65ch">
              <b-row class="no-gutters text-center">
                <h3 class="m-auto color-mhc-dark">{{ questionnaireCategory }}</h3>
              </b-row>
              <b-row class="no-gutters">
                <p class="m-auto text-center">
                  {{ questionnaireCategory }}. You are free to stop
                  whenever you choose.
                </p>
              </b-row>
            </div>
          </b-col>   
          <b-col md="2" class="text-left col-6">
          </b-col>
        </b-row>
        <b-row
          class="justify-content-between no-gutters m-auto mb-2"
          style="max-width: 90%"
        >
          <b-progress
            max="100"
            variant="mhc-dark"
            height=".5rem"
            width="100%"
            class="mt-2 mb-3"
            style="width: 100%"
          />
        </b-row>
        
        <div class="questionBox pb-3">
          <div class="text-center mb-2">
            <h3 v-if="choosenQuestionnaireCategory.name == 'My mental health and how I feel'">
              Based on your feeling {{ questionnaireSubCategoryName }}, which of the following options best describes how you feel?
            </h3>
            <h3 v-else>
              Select an option that best matches your situation
            </h3>
            <p>Select one option</p>
            <hr class="mhc-divider m-auto" />
          </div>
          <div class="questionContainer">

            <div class="letterFilter px-1">
              <span class="text-uppercase" v-for="(letter, index) in lettersQuestionnaires" :key="index" @click="getQuestionnaires(letter)" v-bind:class="{'green-text': letter.flag, 'text-underline': firstLetterQuestionnaire == letter.letter}">{{ letter.letter }}</span>
            </div>

            <div class="px-1">
              <b-form-input v-model="searchQuestionnaire" placeholder="Search questionnaires" class="mb-1"></b-form-input>
            </div>

            <vue-perfect-scrollbar
              ref="scroll-bar"
              :settings="perfectScrollbarSettings"
              class="scrollable-container media-list scroll-area"
              style="height: 300px"
              suppressScrollX="false"
            >
              <div class="optionContainer px-1">
                <div v-if="questionnaires.length > 0">
                  <div
                    class="option"
                    v-for="(questionnaire, index) in filteredQuestionnaires"
                    :key="index"
                    @click="chooseQuestionnaire(questionnaire.slug, questionnaire.id)"
                  >
                    <span class="option-click">{{ questionnaire.title }}</span>
                  </div>
                </div>
                <div v-else class="text-center">
                  <p>We currently have no questionnaires</p>
                </div>
              </div>
            </vue-perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import store from "@/store/index";
import { BOverlay, BButton, BRow, BCol, BProgress, BLink, BFormInput, BModal, VBTooltip } from "bootstrap-vue";
import Help from "../../components/modals/Help.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from "../../main.js"
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BOverlay,
    BButton,
    BRow,
    BCol,
    BProgress,
    BLink,
    BFormInput,
    BModal,
    Help,
    VuePerfectScrollbar
  },
  setup() {
    // Setup perfect scrollbar
    const perfectScrollbarSettings = {
      wheelPropagation: true,
      suppressScrollX: false,
      useBothWheelAxes: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
  computed: {
    show() {
      return store.state.formStoreModule.show;
    },
    questionnaireCategory() {
      return store.state.formStoreModule.questionnaireCategory;
    },
    filteredList() {
      let searchTerm = this.searchSubcategory;

      return this.questionnaireSubCategories.filter(subcategory => {
        if(subcategory.name == null) {
          subcategory.name = ""
        }

        if(subcategory.name.toLowerCase().includes(searchTerm.toLowerCase())) {              
          return subcategory;
        }
      });       
    },
    filteredQuestionnaires() {
      let searchTerm = this.searchQuestionnaire;

      return this.questionnaires.filter(questionnaire => {        
        if(questionnaire.title.toLowerCase().includes(searchTerm.toLowerCase())) {              
          return questionnaire;
        }
      }); 
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      questionnaireSubCategoryName: "",
      page: 0,
      questionnaires: [],
      progress: 0,
      questionnaireCategories: [],
      questionnaireSubCategories: [],
      choosenQuestionnaireCategory: {
        name: "",
        id: 0
      },
      chosenQuestionnaireSubcategory: null,

      // Letters
      filterOverlay: false,
      searchSubcategory: "",
      searchQuestionnaire: "",
      firstLetterSubactegory: null,
      firstLetterQuestionnaire: null,
      lettersSubcategories: [],
      lettersQuestionnaires: [],

      // Payment
      currentSubscriptionPlan: null,
      currentSubscriptionPrice: null,
      productFullPackage: store.state.app.productFullPackage,
    };
  },
  methods: {
    goBack() {
      // Go to "choose category" screen (where page == 0)
      this.page--;  
      store.commit("formStoreModule/setPage", this.page);
    },
    async chooseCategory(questionnaireCategory) {
      let questionnaireCategoryId = questionnaireCategory.id
      let questionnaireCategoryName = questionnaireCategory.name

      // if the user is not logged in as the category name is not life, then this function should do nothing, since all but life questionnaire category are premium 
      if(questionnaireCategoryName != "My mental health and my life situation(s)" && (localStorage.getItem("userData") == null || this.currentSubscriptionPrice == null)) {
        // open pricing modal
        EventBus.$emit('showPricing', true)
        
        // stop function
        return false;
      }

      this.choosenQuestionnaireCategory = questionnaireCategory

      store.commit("formStoreModule/setShow", true);
      
      // Commit questionnaire category id to store
      store.commit(
        "formStoreModule/setQuestionnaireCategoryId",
        questionnaireCategoryId
      );

      // Get all letters
      await this.getSubcategoryLetters()

      // If firstLetterSubactegory exists call letter endpoint, else call all subcategories
      let url = "/api/questionnaire-subcategories/" + questionnaireCategoryId + "?available=1";

      if(this.firstLetterSubactegory != null && this.firstLetterSubactegory != undefined && this.firstLetterSubactegory != 'all') {
        url = "/api/questionnaire/first-letter/" + questionnaireCategoryId + "/" + this.firstLetterSubactegory;
      }

      // Get subcategories from choosen category
      this.$http.get(url)
      .then((res) => {
        if(res != undefined) {
          this.questionnaireSubCategories = res.data;

          store.commit("formStoreModule/setShow", false);
        }
      })
      .catch((error) => {
        console.log(error);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });

      store.commit(
        "formStoreModule/setQuestionnaireCategory",
        questionnaireCategoryName
      );
      
      this.page ++;
      store.commit("formStoreModule/setPage", this.page);

      if(localStorage.getItem('dont-show-page-1') == null) {
        this.openHelpModal(false)
      }
    },
    async chooseSubCategory(questionnaireSubCategoryId, questionnaireSubCategoryName) {

      this.chosenQuestionnaireSubcategory = questionnaireSubCategoryId;

      await this.getQuestionnaireLetters() 

      store.commit("formStoreModule/setShow", true);
      if(questionnaireSubCategoryName.includes("I feel")) {
        this.questionnaireSubCategoryName = questionnaireSubCategoryName.split("I feel ")[1].toLowerCase();
      }

      // Get questionnaires from choosen subcategory
      //.get("/api/questionnaire/category/"+questionnaireSubCategoryId)
      let url = "/api/auth/questionnaire/subcategory/" + questionnaireSubCategoryId
      if(this.choosenQuestionnaireCategory.name == 'My mental health and my job/career' || this.choosenQuestionnaireCategory.name == "My mental health and how I feel") {
        url = "/api/subcategory-questionnaires/" + questionnaireSubCategoryId
      }

      this.$http.get(url)
      .then((res) => {
        if(res != undefined) {
          this.questionnaires = res.data;
          if(this.choosenQuestionnaireCategory.name == 'My mental health and my job/career' || this.choosenQuestionnaireCategory.name == "My mental health and how I feel") {
            store.commit("formStoreModule/setCurrentQuestionnaire", res.data.id);
            this.page++
            store.commit("formStoreModule/setShow", false);
          } else {
            //Naknadno dodato zbog preseka upita (treba skloniti komentar sa this.page i zameniti endpoint)
            store.commit("formStoreModule/setCurrentQuestionnaire", res.data.id);
            this.next(res.data.slug);
            store.commit("formStoreModule/setShow", false);
          }
        }
      })
      .catch((error) => {
        console.log(error);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });

      store.commit(
        "formStoreModule/setQuestionnaireSubCategory",
        questionnaireSubCategoryName
      );
      //this.page ++;
    },
    chooseQuestionnaire(questionnaireSlug, questionnaireId) {
      store.commit("formStoreModule/setCurrentQuestionnaire", questionnaireId);
      this.next(questionnaireSlug);
    },
    next(questionnaireSlug) {
      this.$router.push("/form/" + questionnaireSlug);
    },
    nextPage() {
      store.dispatch('formStoreModule/incrementPage')
      store.commit("formStoreModule/setPage", this.page);
    },
    getSubcategoryLetters() {
      return new Promise((resolve,reject)=>{
        // Get choosen questionnaire category id 
        let categoryId = store.state.formStoreModule.questionnaireCategoryId

        // Get all letters
        this.$http.get("/api/questionnaire/category/" + categoryId + "/first-letters")
        .then((res) => {
          if(res != undefined) {      
            this.lettersSubcategories = res.data
            this.lettersSubcategories.splice( 0, 0, { letter: 'all', flag: true });

            this.firstLetterSubactegory = 'all'

            resolve();
          }
        })
        .catch((error) => {
          console.log(error);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
      });
    },
    getQuestionnaireLetters() {
      return new Promise((resolve,reject)=>{

        // Get all letters
        this.$http.get("/api/questionnaire/subcategory/" + this.chosenQuestionnaireSubcategory + "/first-letters")
        .then((res) => {
          if(res != undefined) {      
            this.lettersQuestionnaires = res.data
            this.lettersQuestionnaires.splice( 0, 0, { letter: 'all', flag: true });

            this.firstLetterQuestionnaire = 'all'

            resolve();
          }
        })
        .catch((error) => {
          console.log(error);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
      });
    },
    showSubcategories() {
        if(this.choosenQuestionnaireCategory.name == 'My mental health and my job/career' || this.choosenQuestionnaireCategory.name == "My mental health and how I feel") {
          return true;
        }
        return false;
    },
    getSubcategories(letter) {

      // Show overlay
      this.filterOverlay = true

      // Clear search form
      this.searchSubcategory = ""

      // Get choosen questionnaire category id (from store)
      let categoryId = store.state.formStoreModule.questionnaireCategoryId
      
      this.firstLetterSubactegory = letter.letter

      // Call endpoint for all subcategories or for clicked letter
      let url = "/api/questionnaire-subcategories/" + categoryId + "?available=1";

      if(letter.flag == true && letter.letter != 'all') {
        url = "/api/subcategories/first-letter/" + categoryId + "/" + letter.letter;
      }

      this.$http.get(url)
      .then((res) => {
        if(res != undefined) {
          this.questionnaireSubCategories = res.data

          // Hide overlay
          this.filterOverlay = false
          this.$refs['scroll-bar'].$el.scrollTop = 0;
        }
      })
      .catch((error) => {
        console.log(error);

        // Hide overlay
        this.filterOverlay = false
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    },
    getQuestionnaires(letter) {
      // Show overlay
      this.filterOverlay = true

      // Clear search form
      this.searchQuestionnaire = ""
      
      this.firstLetterQuestionnaire = letter.letter

      // Call endpoint for all subcategories or for clicked letter
      let url = "/api/questionnaires/" + this.chosenQuestionnaireSubcategory + "?available=1";

      if(letter.flag == true && letter.letter != 'all') {
        url = "/api/questionnaires/first-letter/" + this.chosenQuestionnaireSubcategory + "/" + letter.letter;
      }

      this.$http.get(url)
      .then((res) => {
        if(res != undefined) {
          this.questionnaires = res.data

          // Hide overlay
          this.filterOverlay = false
          this.$refs['scroll-bar'].$el.scrollTop = 0;
        }
      })
      .catch((error) => {
        console.log(error);

        // Hide overlay
        this.filterOverlay = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    },
    openHelpModal(btnClicked) {
      EventBus.$emit('openHelpModal', btnClicked)
    },
    addIsDisabledOption(category) {
      if(category.name == "My mental health and my life situation(s)" || (localStorage.getItem("userData") != null && this.currentSubscriptionPrice != null)) {
        return "option-not-disabled"
      }
      return "option-disabled";
    },

    getCurrentSubscriptionPlan() {
      this.$http.get('/api/auth/payment/current-plan')
          .then((response) => {
            this.currentSubscriptionPlan = response.data.subscriptions
              if(this.currentSubscriptionPlan.length == 0) {
                  this.currentSubscriptionPrice = null
              } else {
                  this.currentSubscriptionPrice = response.data.subscriptions[0].stripe_price
              }
          })
          .catch((err) => {
              console.log(err)
              this.show = false

              // Show toast
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "Something went wrong. Please try again",
                      icon: "AlertCircleIcon",
                      variant: "danger",
                  },
              });
          })
    }
  },
  created() {
    // Reset all params
    store.dispatch('formStoreModule/resetFormParams')

    // Return all available questionnaire categories
    this.$http.get("/api/questionnaire-category")
      .then((res) => {
        if(res != undefined) {
          this.questionnaireCategories = res.data;
          store.commit("formStoreModule/setShow", false);

          // Check local storage dont-show-page-0 item
          // Check if user clicked "Don't show message again"
          if(localStorage.getItem('dont-show-page-0') == null) {
            this.openHelpModal(false)
          }
        }
      })
      .catch((error) => {
        console.log(error);

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
  },
  mounted() {
    var elmnt = document.getElementsByClassName("questionnaire-placeholder")[0];
    elmnt.scrollIntoView();

    // If User clicked on How it works in email
    if(this.$route.query.howitworks == 1) {
      EventBus.$emit('howItWorks');
    }

    // Questionnaire params after survey stopped
    let qId = this.$route.query.qid
    let qName = this.$route.query.qname

    if(qId != undefined || qName !=undefined) {
      this.chooseCategory(qId, qName)
    }

    // Get current subscription plan
    if(localStorage.getItem("userData") != null) {
      this.getCurrentSubscriptionPlan();
    }

  }
};
</script>

<style scoped>
  .helpBtn {
    max-width:90%;
    text-align: right;
    cursor: pointer;
  }

  label.custom-control-label:hover {
    /*background: #59949c 0% 0% no-repeat padding-box;
    border: 1px solid #59949c;
    border-radius: 8px;
    */
  }

  .letterFilter {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .letterFilter span {
    color: #bbb;
  }

  .option-disabled {
    background-color: #ebebeb !important;
    border: 1px solid #59949c !important;
  }

  .option-disabled:hover {
    color: #37465A !important;
  }

  .text-underline {
    text-decoration: underline;
  }

  .green-text {
    color: #59949c!important;
    font-weight: bold;
    cursor: pointer;
  }
</style>